

























// @vuese
// @group Tables
// Trial Users Table
import {
  distributorLabel,
  resellerLabel
} from '@/router'
import {Store} from '@/store/store'
import {Component, Prop, Emit } from 'vue-property-decorator';
import TableLoading from './TableLoading.vue'

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
}

/**
 * 
 * This component renders a list of trial accounts that the user can see
 * 
 */

@Component({
  components:{
    TableLoading
  }
})
export default class TrialUsersTable extends Store {
  DistiFields: Array<any> = [
    {key: 'admin_letter', label: '', tdClass: 'admin-letter'},
    {key: 'email', label: 'Email', tdClass: 'email w20'},
    {key: 'meeting_room_alias', label: 'Alias'},
    {key: 'created_at', label: 'Invited'},
    {key: 'activated_at', label: 'Activated'},
    {key: 'is_pending', label: 'Status', tdClass: 'status'},
    {key: 'days_left', label: 'Days Remaining'},
  ];

  ResellerFields: Array<any> = [
    {key: 'admin_letter', label: '', tdClass: 'admin-letter'},
    {key: 'email', label: 'Email', tdClass: 'email w20'},
    {key: 'meeting_room_alias', label: 'Alias'},
    {key: 'created_at', label: 'Invited'},
    {key: 'activated_at', label: 'Activated'},
    {key: 'is_pending', label: 'Status', tdClass: 'status'},
    {key: 'days_left', label: 'Days Remaining'},
    {key: 'manage', label: 'Resend Invite'},
    {key: 'delete',label: 'Delete'},
  ];

  @Prop({
    default() {
      return []
    }
  })items!: Array<CustomerUsers>;

  @Prop({
    default() {
      return {}
    }
  })trial!: {};

  /* tslint:disable:no-empty */
  /**
   * Fired when the user triigers an action
   * @vuese
   */  
  @Emit()
  refresh(){}

  get fields() {
    if (this.typeOfAccount == resellerLabel) {
      return this.ResellerFields
    } else {
      return this.DistiFields
    }
  }

  get itemsExtended(){
    if(this.trial) {
      const output: any = [];
      
      this.items?.forEach((user) => {
        output.push({
          ...user, 
          ...this.trial
        })
      })
    
      return output
    } else {
      return this.items
    }
  }

  /**
   * Check if there is only one admin. If so they can not be deleted
   */  
  isOnlyAdmin(user: any) {
    if(user.role === 'Admin') {
      let admins: number = 0
      this.items.forEach(item => {item.role === 'Admin' ? admins++ : null})
      return admins < 2
    }
    return false
  }

  /**
   * Resend invite to user
   */ 
  resendInvite(user: any) {
    this.resendCustomerUserInvite({
      userId: parseInt(user.id), 
    })
    .then(() => {
      this.$toast.success('Resent customer invite')
    })
    .catch(() => {
      this.$toast.error('Resent customer invite failed')
    })
  }

  /**
   * Delete this user
   */ 
  deleteUser(user: any) {
    this.deleteCustomerUser({
      userId: parseInt(user.id), 
    })
    .then(() => {
      this.$toast.success('Customer deleted')
      this.refresh()
    })
    .catch(() => {
      this.$toast.error('Customer deletion failed')
    })
  }
}
