


































































































































































































import { validateEmailAddress as emailCheck } from "@/components/utils/EmailParser";
import GenericForm from '@/components/forms/GenericForm.vue'
import TrialUsersTable from "@/components/tables/TrialUsersTable.vue";

import {Store} from '@/store/store'
import {Component, Prop, Emit, Watch } from 'vue-property-decorator';

interface CustomerUsers {
  activated_at?: string;
  addons?: Array<any>;
  billing_plan?: any;
  created_at?: string;
  email?: string;
  id?: number;
  is_pending?: boolean;
  last_name?: string;
  meeting_room_alias?: string;
  name?: string;
  role?: string;
}

interface NewEmail {
  email: string;
  alias?: string;
  is_admin: boolean;
}

interface Errors {
  businessTrialUser1Email: Array<string>;
  businessTrialUser2Email: Array<string>;
  businessTrialUser3Email: Array<string>;
  businessTrialUser1Alias: Array<string>;
  businessTrialUser2Alias: Array<string>;
  businessTrialUser3Alias: Array<string>;
}

@Component({
  components:{
    GenericForm,
    TrialUsersTable,
  }
})
export default class TrialUsers extends Store {
  componentTitle = 'Trial Users'

  @Prop()createErrors: any

  customerID = '';
  
  trial = {
    trial_extended: false,
    trial_ends_at: '',
    trial_expired_at: '',
  }
  customerUsers: Array<CustomerUsers> = [];
  newCustomerUsers: Array<NewEmail> = [
    {
      email: "",
      alias: "",
      is_admin: true
    },
    {
      email: "",
      alias: "",
      is_admin: false
    },
    {
      email: "",
      alias: "",
      is_admin: false
    }
  ];

  newUsers: Array<NewEmail> = []

  errors: Errors = {
    businessTrialUser1Email: [],
    businessTrialUser2Email: [],
    businessTrialUser3Email: [],
    businessTrialUser1Alias: [],
    businessTrialUser2Alias: [],
    businessTrialUser3Alias: [],
  }

  trialsErrorMessage = '';
  trialsSuccessMessage = '';
  isTrial = false;
  isCreateForm = false;


  // Computed
  get isDisabled() {
    return (this.isReseller && this.isTrial) || this.isCreateForm ? false : true;
  }

  get noEmails(): boolean {
    return this.newCustomerUsers[0].email == '' && this.newCustomerUsers[1].email == '' && this.newCustomerUsers[2].email == ''
  }

  get trialsAvailable(): number {
    return this.customerUsers.length ? 3 - this.customerUsers.length : 3;
  }

  created() {
    this.customerID = this.$route.params.id;
  }

  async mounted() {
    if (!this.customerID) {
      this.isCreateForm = true;
      this.componentTitle = "Create Customer Account";
    } else {
      await this.refresh();
    }
  }

  async refresh() {
    if (this.customerID) {
      const companyAccount = await this.getCompanyAccount(this.customerID);

      if(companyAccount) {
        this.isTrial = companyAccount.is_trial;
        // trial details
        this.trial.trial_extended = companyAccount.extended_trial;
        this.trial.trial_ends_at = companyAccount.trial_ends_at;
        this.trial.trial_expired_at = companyAccount.trial_expired_at;
        // Save users
        this.customerUsers = (companyAccount.users as unknown) as CustomerUsers[];
        // Reset defaults
        this.resetNewUsers();
      }
    }
  }

  @Watch('newCustomerUsers', {deep: true})
  @Emit()
  upLiftData() {
    if(!this.customerID) {
      return this.validateNewUsers()
    }
  }

  @Watch('createErrors', {deep: true})
  mapCreateErrors() {
    this.mapFormErrors(this.createErrors)
  }

  // Users

  validateNewUsers() {
    const checkedUsers: Array<NewEmail> = [];
    const emailErrors: Array<string> = [];

    this.newCustomerUsers.forEach((item, index) => {
      let emailX: Array<string> = [];

      // If email field is empty
      if (item.email == '') {
        emailErrors[index] = '' // so that the array is the right size
      } else {
        // Is email in a real format
        emailX = emailCheck(item.email);
        if (emailX.length == 0) {
          emailErrors[index] = 'Email address is invalid'

        // Does email already exist in this list of users
        } else if (this.customerUsers.find(x => x.email === item.email)) {
          emailErrors[index] = 'Email address is already used'

        // This email is valid to be passed to the API
        } else {
          emailErrors[index] = 'valid' // so that the array is the right size

          // Create User Object
          const newUser: NewEmail = {
            email: item.email,
            is_admin: item.is_admin
          }
          // Only add alias if it is a value
          if(item.alias) {newUser.alias = item.alias}

          // Add user to checked array
          checkedUsers.push(newUser);
        }
      }
    });

    let passedErrors = 0
    // Assign email check errors to correct field
    if(emailErrors.length > 0) {
      this.clearFormErrors();
      if(this.showEmailError(emailErrors[0])) {this.errors.businessTrialUser1Email[0] = emailErrors[0]; passedErrors++};
      if(this.showEmailError(emailErrors[1])) {this.errors.businessTrialUser2Email[0] = emailErrors[1]; passedErrors++};
      if(this.showEmailError(emailErrors[2])) {this.errors.businessTrialUser3Email[0] = emailErrors[2]; passedErrors++};
    }

    // When creating a customer one trial user must be created
    if(passedErrors == 0 && !checkedUsers.length && !this.customerUsers.length) {
      this.errors.businessTrialUser1Email[0] = 'One Trial User is required';
      passedErrors++
    }

    if(passedErrors > 0) {
      this.trialsErrorMessage = "The form contains errors"
    }
    return checkedUsers.length > 0 ? checkedUsers : []
  }

  showEmailError(errorIndex: string) {
    return errorIndex == undefined || errorIndex == '' || errorIndex == 'valid' ? false : true;
  }

  resetNewUsers() {
    this.newCustomerUsers.forEach(item => {
      item.email = "";
      item.alias = "";
      item.is_admin = false;
    });
    this.newCustomerUsers[0].is_admin = true;
  }

  saveTrialLicenses() {
    this.clearFormErrors()
    this.newUsers = this.validateNewUsers();

    const plural = this.newUsers.length > 1 ? "s" : "";
    if (this.newUsers.length > 0) {
      this.inviteCustomerUser({
        companyId: this.customerID,
        inviteUserToCompany: {
          users: this.newUsers
        }
      })
        .then(() => {
          this.trialsSuccessMessage = "New trial users invites have been sent"
          this.$toast.success("New user" + plural + " invited");
          this.refresh();
        })
        .catch((res) => {
          this.mapFormErrors(res)
          this.$toast.error("Failed To New user" + plural);
        });
    } else {
      this.trialsErrorMessage = "The form contains an invalid email address. Please check and try again"
      this.$toast.error("Invalid Email address. Please check");
    }
  }

  // Errors
  mapFormErrors(formErrors: any) {
    // Trial Users
    if (this.customerUsers.length == 2) {
      this.errors.businessTrialUser3Email = formErrors.errors["users.0.email"] && formErrors.errors["users.0.email"].length ? formErrors.errors["users.0.email"] : []
      this.errors.businessTrialUser3Alias = formErrors.errors["users.0.alias"] && formErrors.errors["users.0.alias"].length ? formErrors.errors["users.0.alias"] : []
    } else if (this.customerUsers.length == 1) {
      this.errors.businessTrialUser2Email = formErrors.errors["users.0.email"] && formErrors.errors["users.0.email"].length ? formErrors.errors["users.0.email"] : []
      this.errors.businessTrialUser2Alias = formErrors.errors["users.0.alias"] && formErrors.errors["users.0.alias"].length ? formErrors.errors["users.0.alias"] : []
      this.errors.businessTrialUser3Alias = formErrors.errors["users.1.alias"] && formErrors.errors["users.1.alias"].length ? formErrors.errors["users.1.alias"] : []
    } else {
      this.errors.businessTrialUser1Email = formErrors.errors["users.0.email"] && formErrors.errors["users.0.email"].length ? formErrors.errors["users.0.email"] : []
      this.errors.businessTrialUser1Alias = formErrors.errors["users.0.alias"] && formErrors.errors["users.0.alias"].length ? formErrors.errors["users.0.alias"] : []
      this.errors.businessTrialUser2Alias = formErrors.errors["users.1.alias"] && formErrors.errors["users.1.alias"].length ? formErrors.errors["users.1.alias"] : []
      this.errors.businessTrialUser3Alias = formErrors.errors["users.2.alias"] && formErrors.errors["users.2.alias"].length ? formErrors.errors["users.2.alias"] : []
    }

    // If the form returns a generic users error, add it to the user error array
    if(formErrors.errors["users"] && formErrors.errors["users"].length) {
      this.errors.businessTrialUser1Email.unshift(formErrors.errors["users"][0])
    }

    let errorCount = 0
    Object.keys(this.errors).map((i:string)=>{
        //@ts-ignore
        if(this.errors[i].length > 0) {errorCount++}
    })
    if(errorCount == 0) {
      this.trialsErrorMessage = formErrors.message
    }
  }

  /**
   * Clears all form errors from form
   * @vuese
   */
  clearFormErrors(){
    this.trialsSuccessMessage = ''
    this.trialsErrorMessage = ''
    // Trial Users
    this.errors.businessTrialUser1Email = []
    this.errors.businessTrialUser2Email = []
    this.errors.businessTrialUser3Email = []
    this.errors.businessTrialUser1Alias = []
    this.errors.businessTrialUser2Alias = []
    this.errors.businessTrialUser3Alias = []
  }
}
