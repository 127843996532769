















































































































































































































































































































import GenericForm from '@/components/forms/GenericForm.vue'
import ConfirmationPopup from "@/components/popup/ConfirmationPopup.vue";

import {Store} from '@/store/store'
import {Component, Prop, Emit, Watch } from 'vue-property-decorator';

interface Customer {
  companyId: string;
  method: string;
  businessProfileBusinessName: string;
  businessProfileLeadContact: string;
  businessProfileEmail: string;
  businessProfileWebAddress: string;
  businessProfileAddressLine1: string;
  businessProfileAddressLine2: string;
  businessProfileTown: string;
  businessProfileCountyState: string;
  businessProfilePostcode: string;
  businessProfileCountry: string;

  businessBrandGuestWelcomeMessage?: string;
  businessBrandPrimaryColorHex?: string;
  businessBrandSecondaryColorHex?: string;
  businessBrandFaviconUrl?: string;

  businessBrandLogo?: string;
  businessBrandHeroImage?: string;
  businessBrandFavicon?: string;
}

interface CustomerBrand {
  guest_welcome_message?: string;
  primary_color_hex?: string;
  secondary_color_hex?: string;
  logo?: string;
  hero_image?: string;
  favicon?: string;
  favicon_url?: string;
}

interface Errors {
  businessBrandPrimaryColorHex: Array<string>;
  businessBrandSecondaryColorHex: Array<string>;
  businessBrandLogo: Array<string>;
  businessBrandHero: Array<string>;
  businessBrandFavicon: Array<string>;
  businessBrandFaviconUrl: Array<string>;
}

@Component({
  components: {
    GenericForm,
    ConfirmationPopup
  }
})
export default class BusinessBranding extends Store {
  componentTitle = 'Business Branding'

  @Prop()createErrors: any

  customerID = '';

  logoFile = "";
  heroFile = "";
  faviconFile = "";

  imageToBeDeleted = "";
  isShowConfirmDeleteImage = false;
  showFaviconFileInput = false;

  customerAccount: any = {};

  customerBrand: CustomerBrand = {
    guest_welcome_message: '',
    primary_color_hex: '',
    secondary_color_hex: '',
    logo: '',
    hero_image: '',
    favicon: '',
    favicon_url: '',
  }
  existingBrandImages: any = {
    logo: '',
    hero_image: '',
    favicon: '',
    favicon_url: '',
  }

  defaultColours: CustomerBrand = {
    primary_color_hex: "#fd695a",
    secondary_color_hex: "#24222e"
  };

  errors: Errors = {
    businessBrandPrimaryColorHex: [],
    businessBrandSecondaryColorHex: [],
    businessBrandLogo: [],
    businessBrandHero: [],
    businessBrandFavicon: [],
    businessBrandFaviconUrl: [],
  }

  brandErrorMessage = '';
  brandSuccessMessage = '';
  isTrial = false;
  isCreateForm = false;

  created() {
    this.customerID = this.$route.params.id;
    if (!this.customerID) {
      this.isCreateForm = true;
      this.componentTitle = "Create Customer Branding";
    }
  }

  async mounted() {
    if (!this.isCreateForm) {
      await this.refresh();
    } else {
      this.setDefaultColours(true)
    }
  }

  async refresh() {
    if (this.customerID) {
      const companyAccount = await this.getCompanyAccount(this.customerID);
      if(companyAccount) {
        this.isTrial = companyAccount.is_trial;
        this.customerAccount = companyAccount.business_profile;
        this.customerBrand.guest_welcome_message = companyAccount.business_brand.guest_welcome_message;
        this.customerBrand.primary_color_hex = companyAccount.business_brand.primary_color_hex;
        this.customerBrand.secondary_color_hex = companyAccount.business_brand.secondary_color_hex;
        // Reset new branding
        this.customerBrand.logo = "";
        this.customerBrand.hero_image = "";
        this.customerBrand.favicon = "";
        this.customerBrand.favicon_url = companyAccount.business_brand.favicon_url;
        // Save existing branding
        this.existingBrandImages.logo = companyAccount.business_brand.logo;
        this.existingBrandImages.hero_image = companyAccount.business_brand.hero_image;
        this.existingBrandImages.favicon = companyAccount.business_brand.favicon;
        this.existingBrandImages.favicon_url = companyAccount.business_brand.favicon_url

        this.showFaviconFileInput = companyAccount.business_brand.favicon ? true : false

        this.setDefaultColours()
      }
    }
  }

  @Watch('customerBrand', {deep: true})
  @Emit()
  upLiftData() {
    if(!this.customerID) {
      return this.customerBrand
    }
  }

  @Watch('createErrors', {deep: true})
  mapCreateErrors() {
    this.mapFormErrors(this.createErrors)
  }

  // Computed
  get isDisabled() {
    return (this.isReseller && this.isTrial) || this.isCreateForm ? false : true;
  }

  get isFaviconFileDeletable(): boolean {
    return !!this.existingBrandImages.favicon;
  }

  get isLogoFileDeletable(): boolean {
    return !!this.existingBrandImages.logo;
  }

  get isHeroImageFileDeletable(): boolean {
    return !!this.existingBrandImages.hero_image;
  }

  // Branding image previews

  get Logo(): string | undefined {
    return this.logoFile
      ? this.objectURL(this.logoFile)
      : this.existingBrandImages.logo;
  }

  get Hero(): string | undefined {
    return this.heroFile
      ? this.objectURL(this.heroFile)
      : this.existingBrandImages.hero_image;
  }

  get faviconURL(): string | undefined {
    if (
      this.customerBrand.favicon_url &&
      this.customerBrand.favicon_url !== this.existingBrandImages.favicon_url &&
      this.isValidURL(this.customerBrand.favicon_url)
    ) {
      this.existingBrandImages.favicon_url = this.customerBrand.favicon_url;
      return (
        "https://www.google.com/s2/favicons?sz=32&domain_url=" +
        this.customerBrand.favicon_url
      );
    }
  }

  get faviconFileURL(): string | undefined {
    return this.faviconFile
      ? this.objectURL(this.faviconFile)
      : this.existingBrandImages.favicon;
  }

  get selectFavicon() {
    // return this.showFaviconFileInput ? this.faviconFileURL : this.faviconURL;
    return this.faviconURL ? this.faviconURL : this.faviconFileURL;
  }

  get resetColours() {
    return (
      this.customerBrand.primary_color_hex !== this.defaultColours.primary_color_hex || this.customerBrand.secondary_color_hex !== this.defaultColours.secondary_color_hex
    );
  }


  // Methods 
  updateEditorColor(e: any) {
    this.customerBrand.primary_color_hex = e.target.value;
  }

  onLogoFileSelected(event: any) {
    this.logoFile = event.target.files[0];
    this.customerBrand.logo = this.objectURL(this.logoFile);
  }

  onFaviconFileSelected(event: any) {
    this.faviconFile = event.target.files[0];
    this.customerBrand.favicon = this.objectURL(this.faviconFile);
  }

  onHeroImageFileSelected(event: any) {
    this.heroFile = event.target.files[0];
    this.customerBrand.hero_image = this.objectURL(this.heroFile);
  }

  isValidURL(string: string) {
    // eslint-disable-next-line
    let res = string.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  }

  confirmDeleteImage(image: string) {
    this.imageToBeDeleted = image;
    this.isShowConfirmDeleteImage = true;
  }

  cancelDeleteImage() {
    this.isShowConfirmDeleteImage = false;
    this.imageToBeDeleted = "";
  }

  faviconInputToggle() {
    this.showFaviconFileInput = !this.showFaviconFileInput;
  }

  setDefaultColours(force?: boolean) {
    if (!this.customerBrand.primary_color_hex || force) {
      this.customerBrand.primary_color_hex = this.defaultColours.primary_color_hex;
    }
    if (!this.customerBrand.secondary_color_hex || force) {
      this.customerBrand.secondary_color_hex = this.defaultColours.secondary_color_hex;
    }
  }

  objectURL(file: string | null): string {
    return file ? URL.createObjectURL(file) : "";
  }

  deleteImage() {
    this.isShowConfirmDeleteImage = false;
    this.deleteBrandingImage({
      companyId: this.customerID,
      image: this.imageToBeDeleted
    })
      .then(() => {
        this.$toast.success("Deleted " + this.imageToBeDeleted + " image file");
        this.imageToBeDeleted = "";
        this.refresh();
      })
      .catch(() => {
        this.$toast.error(
          "Failed to delete " + this.imageToBeDeleted + " image file"
        );
      });
  }

  resetBranding() {
    this.restoreDefaultBranding({
      companyId: this.customerID
    })
      .then(() => {
        this.$toast.success("Company branding reset to defaults");
        this.logoFile = "";
        this.heroFile = "";
        this.faviconFile = "";
        this.refresh();
      })
      .catch(() => {
        this.$toast.error("Failed to reset company branding");
      });
  }

  updateBusinessBranding() {
    const args: any = {
      companyId: this.customerID,
      method: "POST",
      businessProfileBusinessName: this.customerAccount.business_name,
      businessProfileLeadContact: this.customerAccount.lead_contact,
      businessProfileEmail: this.customerAccount.email,
      businessProfileWebAddress: this.customerAccount.web_address,
      businessProfileAddressLine1: this.customerAccount.address_line_1,
      businessProfileAddressLine2: this.customerAccount.address_line_2,
      businessProfileTown: this.customerAccount.town,
      businessProfileCountyState: this.customerAccount.county_state,
      businessProfilePostcode: this.customerAccount.postcode,
      businessProfileCountry: this.customerAccount.country,

      businessBrandGuestWelcomeMessage: this.customerBrand.guest_welcome_message,
      businessBrandPrimaryColorHex: this.customerBrand.primary_color_hex,
      businessBrandSecondaryColorHex: this.customerBrand.secondary_color_hex,
    };

    if (this.logoFile !== "") {
      args.businessBrandLogo = this.logoFile;
    }
    if (this.heroFile !== "") {
      args.businessBrandHeroImage = this.heroFile;
    }
    if (this.faviconFile !== "") {
      args.businessBrandFavicon = this.faviconFile;
    } else {
      args.businessBrandFaviconUrl = this.customerBrand.favicon_url
    }

    this.clearFormErrors()
    this.updateCustomerAccount(args)
      .then(() => {
        this.brandSuccessMessage = "The customers business branding has been updated"
        this.$toast.success("Updated Customer Branding");
        this.refresh();
      })
      .catch((res) => {
        this.mapFormErrors(res)
        this.$toast.error("Failed To Update Customer Branding");
      });
  }

  // Errors

  mapFormErrors(formErrors: any) {
    // Branding
    this.errors.businessBrandPrimaryColorHex = formErrors.errors["business_brand.primary_color_hex"] && formErrors.errors["business_brand.primary_color_hex"].length ? formErrors.errors["business_brand.primary_color_hex"] : []
    this.errors.businessBrandSecondaryColorHex = formErrors.errors["business_brand.secondary_color_hex"] && formErrors.errors["business_brand.secondary_color_hex"].length ? formErrors.errors["business_brand.secondary_color_hex"] : []
    this.errors.businessBrandLogo = formErrors.errors["business_brand.logo"] && formErrors.errors["business_brand.logo"].length ? formErrors.errors["business_brand.logo"] : []
    this.errors.businessBrandHero = formErrors.errors["business_brand.hero"] && formErrors.errors["business_brand.hero"].length ? formErrors.errors["business_brand.hero"] : []
    this.errors.businessBrandFavicon = formErrors.errors["business_brand.favicon"] && formErrors.errors["business_brand.favicon"].length ? formErrors.errors["business_brand.favicon"] : []
    this.errors.businessBrandFaviconUrl = formErrors.errors["business_brand.favicon_url"] && formErrors.errors["business_brand.favicon_url"].length ? formErrors.errors["business_brand.favicon_url"] : []

    let errorCount = 0
    for(const item in this.errors) {
      if(item.length > 0) {errorCount++}
    }
    this.brandErrorMessage = errorCount > 0 ? formErrors.message : ''
  }

  /**
   * Clears all form errors from form
   * @vuese
   */
  clearFormErrors(){
    this.brandSuccessMessage = ''
    this.brandErrorMessage = ''
    // Branding
    this.errors.businessBrandPrimaryColorHex = []
    this.errors.businessBrandSecondaryColorHex = []
    this.errors.businessBrandLogo = []
    this.errors.businessBrandHero = []
    this.errors.businessBrandFavicon = []
    this.errors.businessBrandFaviconUrl = []
  }
}
