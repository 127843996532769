
























































































































































































































import GenericForm from '@/components/forms/GenericForm.vue'

import {Store} from '@/store/store'
import {Component, Prop, Emit, Watch } from 'vue-property-decorator';

interface Errors {
  profileErrorMessage: string,
  businessProfileBusinessName: Array<string>;
  businessProfileLeadContact: Array<string>;
  businessProfileEmail: Array<string>;
  businessProfileWebAddress: Array<string>;
  businessProfileAddressLine1: Array<string>;
  businessProfileAddressLine2: Array<string>;
  businessProfileTown: Array<string>;
  businessProfileCountyState: Array<string>;
  businessProfilePostcode: Array<string>;
  businessProfileCountry: Array<string>;
}

@Component({
  components:{
    GenericForm
  }
})
export default class AccountDetails extends Store {
  componentTitle = 'Account Details'

  @Prop()createErrors: any

  customerID: string = '';
  
  customerAccount: any = {
    business_name: '',
    lead_contact: '',
    email: '',
    web_address: '',
    address_line_1: '',
    address_line_2: '',
    town: '',
    county_state: '',
    postcode: '',
    country: '',
  }

  errors: Errors = {
    profileErrorMessage: '',
    businessProfileBusinessName: [],
    businessProfileLeadContact: [],
    businessProfileEmail: [],
    businessProfileWebAddress: [],
    businessProfileAddressLine1: [],
    businessProfileAddressLine2: [],
    businessProfileTown: [],
    businessProfileCountyState: [],
    businessProfilePostcode: [],
    businessProfileCountry: [],
  }

  profileSuccessMessage = "";
  isTrial = false;
  isCreateForm = false;
  isEmailChanging = false as boolean;
  newEmail = '' as string;

  created() {
    this.customerID = this.$route.params.id;
  }

  async mounted() {
    if (!this.customerID) {
      this.isCreateForm = true;
      this.componentTitle = "Create Customer Account";
    } else {
      await this.refresh();
    }
  }

  async refresh() {
    if (this.customerID) {
      const companyAccount = await this.getCompanyAccount(this.customerID);

      if(companyAccount) {
        this.isTrial = companyAccount.is_trial;
        this.customerAccount = companyAccount.business_profile;
      }
    }
  }

  @Watch('customerAccount', {deep: true})
  @Emit()
  upLiftData() {
    if(!this.customerID) {
      return this.customerAccount
    }
  }

  @Watch('createErrors', {deep: true})
  mapCreateErrors() {
    this.mapFormErrors(this.createErrors)
  }

  get isDisabled() {
    return (this.isReseller && this.isTrial) || this.isCreateForm ? false : true;
  }

  updateCustomerAccountDetails() {
    this.clearFormErrors()
    this.updateCustomerAccount({
      companyId: this.customerID,
      method: "POST",
      businessProfileBusinessName: this.customerAccount.business_name,
      businessProfileLeadContact: this.customerAccount.lead_contact,
      businessProfileEmail: this.customerAccount.email,
      businessProfileWebAddress: this.customerAccount.web_address,
      businessProfileAddressLine1: this.customerAccount.address_line_1,
      businessProfileAddressLine2: this.customerAccount.address_line_2,
      businessProfileTown: this.customerAccount.town,
      businessProfileCountyState: this.customerAccount.county_state,
      businessProfilePostcode: this.customerAccount.postcode,
      businessProfileCountry: this.customerAccount.country
    })
      .then(() => {
        this.profileSuccessMessage = "The customers details has been updated"
        this.$toast.success("Updated Customer Details");
        this.refresh();
      })
      .catch((res) => {
        this.mapFormErrors(res)
        this.$toast.error("Failed To Update Customer Details");
      });
  }

  deleteAccount() {
    this.$toast.error("Not Implemented Yet");
  }

  changeEmailForm(value: boolean): void {
    this.isEmailChanging = value;
  }

  sendNewEmail() {    
    this.updateCustomerEmail({
      companyId: '60',
      updateCustomer: {
        email: this.newEmail
      }
    })
      .then(() => {
        this.refresh();
        this.profileSuccessMessage = "The customers email has been updated";
        this.$toast.success("Updated Customer Email");
        this.changeEmailForm(false);
        this.newEmail = "";
      })
      .catch((res) => {
        this.mapFormErrors(res)
        this.$toast.error("Failed To Update Customer Email");
      });
  }

  // Errors
  mapFormErrors(formErrors: any) {
    this.errors.profileErrorMessage = formErrors.message
    // Profile
    this.errors.businessProfileBusinessName = formErrors.errors["business_profile.business_name"] && formErrors.errors["business_profile.business_name"].length ? formErrors.errors["business_profile.business_name"] : []
    this.errors.businessProfileLeadContact = formErrors.errors["business_profile.lead_contact"] && formErrors.errors["business_profile.lead_contact"].length ? formErrors.errors["business_profile.lead_contact"] : []
    this.errors.businessProfileEmail = formErrors.errors["business_profile.email"] && formErrors.errors["business_profile.email"].length ? formErrors.errors["business_profile.email"] : []
    this.errors.businessProfileWebAddress = formErrors.errors["business_profile.web_address"] && formErrors.errors["business_profile.web_address"].length ? formErrors.errors["business_profile.web_address"] : []
    this.errors.businessProfileAddressLine1 = formErrors.errors["business_profile.address_line_1"] && formErrors.errors["business_profile.address_line_1"].length ? formErrors.errors["business_profile.address_line_1"] : []
    this.errors.businessProfileAddressLine2 = formErrors.errors["business_profile.address_line_2"] && formErrors.errors["business_profile.address_line_2"].length ? formErrors.errors["business_profile.address_line_2"] : []
    this.errors.businessProfileTown = formErrors.errors["business_profile.town"] && formErrors.errors["business_profile.town"].length ? formErrors.errors["business_profile.town"] : []
    this.errors.businessProfileCountyState = formErrors.errors["business_profile.county_state"] && formErrors.errors["business_profile.county_state"].length ? formErrors.errors["business_profile.county_state"] : []
    this.errors.businessProfilePostcode = formErrors.errors["business_profile.postcode"] && formErrors.errors["business_profile.postcode"].length ? formErrors.errors["business_profile.postcode"] : []
    this.errors.businessProfileCountry = formErrors.errors["business_profile.country"] && formErrors.errors["business_profile.country"].length ? formErrors.errors["business_profile.country"] : []
  }

  /**
   * Clears all form errors from form
   * @vuese
   */
  clearFormErrors(){
    this.profileSuccessMessage = ''
    this.errors.profileErrorMessage = ''
    // Profile
    this.errors.businessProfileBusinessName = []
    this.errors.businessProfileLeadContact = []
    this.errors.businessProfileEmail = []
    this.errors.businessProfileWebAddress = []
    this.errors.businessProfileAddressLine1 = []
    this.errors.businessProfileAddressLine2 = []
    this.errors.businessProfileTown = []
    this.errors.businessProfileCountyState = []
    this.errors.businessProfilePostcode = []
    this.errors.businessProfileCountry = []
  }
}
